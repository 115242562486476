import React, { useEffect, useState } from "react";
import { SubHeading, MenuItem, Hamburger } from "../../components";
import "./Menu.css";
import Modal from 'react-modal';
import { urlFor, getBurgers, getExtras, getDrinks, getBeers, getGin, getInternationalCoctails, getOmniaCoctails, getTapas, getVodka, getWhiskey, getTequila, getShot } from "../../client";
import LanguageSwitch from "../../components/LanguageSwitch/LanguageSwitch";

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    position: 'relative',
    background: "#000",
    maxWidth: '600px',
    maxHeight: '80vh',
    margin: 'auto',
    border: 'none',
    borderRadius: '16px',
    padding: '20px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.6)',
  },
};

const Menu = () => {
  const [burgers, setBurgers] = useState([]);
  const [extras, setExtras] = useState([]);
  const [drinks, setDrinks] = useState([]);
  const [beers, setBeers] = useState([]);
  const [internationalCoctails, setInternationalCoctails] = useState([]);
  const [omniaCoctails, setOmniaCoctails] = useState([]);
  const [tapas, setTapas] = useState([]);
  const [tequila, setTequila] = useState([]);
  const [whiskey, setWhiskey] = useState([]);
  const [shot, setShot] = useState([]);
  const [gin, setGin] = useState([]);
  const [vodka, setVodka] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [currentLanguage, setCurrentLanguage] = useState('TR');

  const handleLanguageChange = (selectedLanguage) => {
    setCurrentLanguage(selectedLanguage);
  };

  const getData = async () => {
    try {
      setBurgers(await getBurgers());
      setExtras(await getExtras());
      setDrinks(await getDrinks());
      setBeers(await getBeers());
      setInternationalCoctails(await getInternationalCoctails());
      setOmniaCoctails(await getOmniaCoctails());
      setTapas(await getTapas());
      setTequila(await getTequila());
      setWhiskey(await getWhiskey());
      setShot(await getShot());
      setGin(await getGin());
      setVodka(await getVodka());
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  Modal.setAppElement('#root');

  const renderMenuSection = (title, items, Component) => (
    <div className="app__specialMenu-menu_wine flex__center">
      <p className="app__specialMenu-menu_heading">{title}</p>
      <div className="app__specialMenu_menu_items">
        {items.map((item, index) => (
          <Component
            key={item.title + index}
            title={item.title}
            img={item.image ? urlFor(item.image) : undefined}
            price={item.price}
            ingredients={currentLanguage === "TR" ? item.ingredients : item.ingredientsEn}
            description={currentLanguage === "TR" ? item.description : item.descriptionEn}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div className="app__specialMenu flex__center section__padding" id="menu">
      <LanguageSwitch
        currentLanguage={currentLanguage}
        onLanguageChange={handleLanguageChange}
      /> 
      <Modal
        isOpen={openModal}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
      >
        <p className="warning__cormorant" style={{ color: "red", fontWeight: "bold" }}>
          Herhangi bir ürüne alerjiniz varsa sipariş öncesinde lütfen belirtiniz.
        </p>
        <button
          onClick={() => setOpenModal(false)}
          type="button"
          className="custom__button"
          style={{ alignSelf: "center", marginTop: 15 }}
        >
          Menüye Devam Et
        </button>
      </Modal>

      <div className="app__specialMenu-title">
        <SubHeading title="Omnia Story Burger Menü" />
        <h1 className="headtext__cormorant">Story's Burger</h1>
      </div>

      {renderMenuSection("Hamburgerler", burgers, Hamburger)}
      {renderMenuSection("Tapaslar", tapas, Hamburger)}
      {renderMenuSection("Ekstralar", extras, MenuItem)}
      {renderMenuSection("Alkolsüz İçecekler", drinks, MenuItem)}
      {renderMenuSection("Biralar", beers, MenuItem)}
      {renderMenuSection("Omnia İmza Kokteyller", omniaCoctails, MenuItem)}
      {renderMenuSection("International Kokteyller", internationalCoctails, MenuItem)}
      {renderMenuSection("Viskiler", whiskey, MenuItem)}
      {renderMenuSection("Cinler", gin, MenuItem)}
      {renderMenuSection("Tekilalar", tequila, MenuItem)}
      {renderMenuSection("Votkalar", vodka, MenuItem)}
      {renderMenuSection("Shotlar", shot, MenuItem)}
    </div>
  );
}

export default Menu;
