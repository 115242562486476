import React, { useState } from 'react';

import './LanguageSwitch.css';

const LanguageSwitch = ({ currentLanguage, onLanguageChange }) => {
  const handleLanguageChange = (selectedLanguage) => {
    onLanguageChange(selectedLanguage);
  };

  return (
    <div className="language-switch">
      <button
        className={`language-button ${currentLanguage === 'TR' ? 'active' : ''}`}
        onClick={() => handleLanguageChange('TR')}
      >
        TR
      </button>
      <button
        className={`language-button ${currentLanguage === 'EN' ? 'active' : ''}`}
        onClick={() => handleLanguageChange('EN')}
      >
        EN
      </button>
    </div>
  );
};

export default LanguageSwitch;
