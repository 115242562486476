import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "@sanity/client";

export const client = sanityClient({
  projectId: "tnfow3dq",
  dataset: "production",
  useCdn: true,
  apiVersion: "2022-01-12",
  token: process.env.REACT_APP_SANITY_TOKEN,
});

export async function getBurgers() {
  const posts = await client.fetch('*[_type == "hamburger"] | order(_updatedAt desc)');
  return posts;
}

export async function getBeers() {
  const posts = await client.fetch('*[_type == "beer"] | order(_updatedAt desc)');
  return posts;
}

export async function getExtras() {
  const posts = await client.fetch('*[_type == "extra"] | order(_updatedAt desc)');
  return posts;
}

export async function getDrinks() {
  const posts = await client.fetch('*[_type == "drink"] | order(_updatedAt desc)');
  return posts;
}

export async function getPosts() {
  const posts = await client.fetch('*[_type == "post"] | order(_updatedAt desc)');
  return posts;
}

export async function getSnacks() {
  const snacks = await client.fetch('*[_type == "snack"] | order(_updatedAt desc)');
  return snacks;
}

export async function getInternationalCoctails() {
  const posts = await client.fetch('*[_type == "internationalCoctails"] | order(_updatedAt desc)');
  return posts;
}

export async function getOmniaCoctails() {
  const posts = await client.fetch('*[_type == "omniaCoctails"] | order(_updatedAt desc)');
  return posts;
}

export async function getTapas() {
  const posts = await client.fetch('*[_type == "tapas"] | order(_updatedAt desc)');
  return posts;
}

export async function getTequila() {
  const posts = await client.fetch('*[_type == "tequila"] | order(_updatedAt desc)');
  return posts;
}

export async function getWhiskey() {
  const posts = await client.fetch('*[_type == "whiskey"] | order(_updatedAt desc)');
  return posts;
}

export async function getShot() {
  const posts = await client.fetch('*[_type == "shot"] | order(_updatedAt desc)');
  return posts;
}

export async function getGin() {
  const posts = await client.fetch('*[_type == "gin"] | order(_updatedAt desc)');
  return posts;
}

export async function getVodka() {
  const posts = await client.fetch('*[_type == "vodka"] | order(_updatedAt desc)');
  return posts;
}




const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
